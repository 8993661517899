@media (max-width: 800px) {
  #lateral_der,
  #lateral_izq {
    display: none;
  }

  #contenedor,
  #contenido,
  #principal {
    background: #fff;
    width: 100%;
    margin: 0;
  }

  #centro {
    width: calc(100% - 36px);
    float: none;
    padding: 0 18px;
  }

  ul.pasos {
    float: none;
    position: unset;
  }

  ul.pasos > li {
    position: unset;
  }

  table tr > td {
    display: block;
    width: 100% !important;
    height: unset !important;
  }
  table tr > td:last-child {
    margin-bottom: 16px;
  }
  table.sectionVariable table tr > td > label {
    float: none;
    text-align: left;
    width: fit-content;
  }
  table.sectionVariable table tr > td:last-child > * {
    width: calc(100% - 16px);
    padding: 8px;
  }
  table.sectionVariable table tr > td:last-child > input[type='radio'],
  table.sectionVariable table tr > td:last-child > input[type='checkbox'] {
    display: inline-block;
    width: auto;
  }
  table.sectionVariable table tr > td:last-child > label.radioLabel,
  table.sectionVariable table tr > td:last-child > label.checkboxLabel {
    display: inline-block;
    width: calc(100% - 48px);
  }

  table.sectionVariable div > p > .campoHoriz {
    width: calc(50% - 16px);
    margin: 0 8px;
    height: 64px;
  }
  table.sectionVariable div > p > .campoHoriz > input,
  table.sectionVariable div > p > .campoHoriz > select {
    width: calc(100% - 16px);
    padding: 8px;
    margin-left: 3px !important;
  }
  table.sectionVariable div > p > .campoHoriz > table tr > td {
    display: inline-block;
    width: auto !important;
    margin-bottom: 0;
  }
  table.sectionVariable div > p > .campoHoriz > table tr > td > label {
    padding: 0;
  }
  table.sectionVariable div > p > .campoHoriz > #kmcalleText,
  table.sectionVariable div > p > .campoHoriz > #kmcalle2 {
    width: calc(50% - 21px) !important;
  }
  table.sectionVariable div > p > .campoHoriz > #dupli,
  table.sectionVariable div > p > .campoHoriz > #bloque,
  table.sectionVariable div > p > .campoHoriz > #escalera,
  table.sectionVariable div > p > .campoHoriz > #puerta,
  table.sectionVariable div > p > .campoHoriz > #piso {
    width: calc(50% - 16px) !important;
  }

  .boton-style.tamano-defecto {
    height: 32px;
    line-height: 32px;
  }
}

@media (max-width: 600px) {
  .titulo {
    height: 100px;
  }
}

@media (max-width: 500px) {
  ul.pasos {
    padding-top: 20px;
  }
  ul.pasos > li {
    float: none;
    margin-top: 0;
    background-image: none !important;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 4px;
  }
  table.sectionVariable div > p > .campoHoriz {
    width: calc(100% - 16px);
    margin: 0 8px;
    height: 64px;
  }
  table.sectionVariable div > p > .campoHoriz > #kmcalleText,
  table.sectionVariable div > p > .campoHoriz > #kmcalle2 {
    width: calc(50% - 21px) !important;
  }
  table.sectionVariable div > p > .campoHoriz > #dupli,
  table.sectionVariable div > p > .campoHoriz > #bloque,
  table.sectionVariable div > p > .campoHoriz > #escalera,
  table.sectionVariable div > p > .campoHoriz > #puerta,
  table.sectionVariable div > p > .campoHoriz > #piso {
    width: calc(50% - 16px) !important;
  }
}
