@CHARSET "ISO-8859-1";

.checkBox
{
	border: 0px;
	border-top: 0px;
	border-left: 0px;
	margin-right: 0px;
	margin-left: 0px;
	font-family: Arial, Helvetica, sans-serif; 
	font-size: 12px;	
	margin-top: 0px;
	background: none;
}

