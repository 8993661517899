label.radioLabel,
label.checkboxLabel {
  float: none;
  display: initial;
}

select.addressSelector {
  width: 20px;
  border-radius: 4px;
  border-color: #a4c1e9;
  color: #3356b2;
}

.boton-style.tamano-defecto {
  line-height: 28px;
  height: 28px;
  margin-left: calc(100% - 70px);
}
